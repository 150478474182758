<template>
    <div>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="avatars"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable"
            disable-pagination
       >

            <template v-slot:item.name="{ item }">
                <b>{{item.name}}</b>
            </template>

            <template v-slot:item.image="{ item }">
              <v-img
                  :src="item.image.url"
                  contain
                  width="100"
                  class="py-5"
              />

            </template>

         <template v-slot:item.actions="{ item }">
           <v-icon
               small
               class="mr-2"
               @click="editAvatar(item)"
           >
             mdi-pencil
           </v-icon>
         </template>

        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getAvatars()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {EventBus} from "@/event-bus";

    export default {
        name: "List",

        data() {
            return {
                avatarFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                  { text: '#', value: 'id', sortable: false, width: '5%'},
                  { text: this.$t('avatars.fields.image'), value: 'image', sortable: false, width: '100px'},
                  { text: this.$t('country'), value: 'country', sortable: false },
                  { text: this.$t('avatars.fields.name'), value: 'name', sortable: false},
                  { text: this.$t('avatars.fields.description'), value: 'description', sortable: false},
                  {text: '', value: 'actions', sortable: false},
                ]
            }
        },

        components: {
        },

        mounted() {
          this.getAvatars()
        },

        methods: {
            getAvatars() {
              this.$store.dispatch('avatar/setAvatars', this.filters)
            },

          editAvatar (avatar) {
            console.log('edit avatar ' , avatar)
            this.isEditing = true
            EventBus.$emit('edit-avatar', avatar)
            this.dialog = true
          },
        },

        computed: {
            ...mapGetters({
                isLoading: 'avatar/loadingState',
                avatars: 'avatar/getAvatars',
                pagination: 'avatar/getPagination'
            }),

            loading: {
                get() {
                    return this.isLoading
                },
                set(v) {
                    this.$store.dispatch('avatar/setLoadingStatus', v)
                }
            },
        }
    }
</script>

<style scoped>

</style>
