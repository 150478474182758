<template>
    <v-sheet class="pa-5">

      <form-avatar></form-avatar>
      <list-avatars></list-avatars>

    </v-sheet>

</template>

<script>

    import List from '@/components/avatars/List'
    import Form from "@/components/avatars/Form";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'list-avatars': List,
          'form-avatar': Form
        }
    }
</script>

<style lang="scss" scoped>
</style>
