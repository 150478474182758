<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="avatar.name"
                    :rules="requiredRules"
                    :label="$t('avatars.fields.name')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-select
                    :items="genders"
                    v-model="avatar.gender"
                    item-value="value"
                    item-text="name"
                    :label="$t('avatars.fields.gender')"
                    :rules="requiredRules"
                    required
                    outlined
                    dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <countries
                    :country.sync="avatar.country"
                    :required-rules="requiredRules"
                ></countries>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="avatar.description"
                    :rules="requiredRules"
                    :label="$t('avatars.fields.description')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <image-upload id="avatar_image"
                              :image.sync="avatar.image"
                              :required-rules="requiredRules"
                              :required="true"
                              btn-label="image"
                ></image-upload>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeAvatar"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import Countries from "@/components/form/Countries";
  import DialogButtons from "@/components/form/DialogButtons";
  import ImageUpload from "@/components/form/ImageUpload";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";

  export default {
    name: "Form",

    components: {DialogButtons, ImageUpload, Countries},

    data: (self) => ({
      avatar: {},
      defaultAvatar: {
        name: '',
        description: '',
        gender: '',
        image: {
          id: ''
        }
      },
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
      genders: [
        {
          name: 'Uomo',
          value: 'M'
        },
        {
          name: 'Donna',
          value: 'F'
        },
      ],
      requiredRules: [
        v => (v !== '' || !!v) || self.$t('commons.errors.validation.required'),
      ],
    }),

    mounted() {

      let self = this

      this.avatar = clonedeep(this.defaultAvatar)

      EventBus.$on('edit-avatar', function (avatar) {
        self.avatar = JSON.parse(JSON.stringify(avatar))
        self.isEditing = true
        self.dialog = true
      })

      EventBus.$on('upload-complete', function (event) {
        console.log('event upload-complete ' , event)
        EventBus.$emit('close-image-dialog')
        self.avatar.image = event.res
      })

    },

    computed: {
      ...mapGetters({
        isLoading: 'avatar/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('avatars.edit.title') : this.$t('avatars.create.title')
      },
    },

    methods: {
      openImageDialog() {
        EventBus.$emit('open-image-dialog', 'avatar', 'avatar')
      },

      async storeAvatar() {
        const valid = await this.$refs.form.validate()

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('avatar/store', this.avatar)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('avatar/update', this.avatar)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.avatar = clonedeep(this.defaultAvatar)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>